import { Component, OnInit, Input, Pipe, PipeTransform } from '@angular/core';
// import { SanitizeHtmlPipe } from '../../services/SanitizeHtmlPipe'
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-cmd-screen',
  templateUrl: './cmd-screen.component.html',
  styleUrls: ['./cmd-screen.component.scss']
})
export class CmdScreenComponent implements OnInit {
  sanitizer: DomSanitizer;
  default_speed: number = 50;

  @Input() speed: number = this.default_speed;

  printing_text: string = '';
  cursor: string = '<text style="color:white">█</text>';

  constructor(
    private _sanitizer: DomSanitizer) {
    this.sanitizer = _sanitizer;
  }
  timer = (ms: number | undefined) => new Promise(res => setTimeout(res, ms))

  ngOnInit(): void {
  }

  async onStartCounting(words: string) {
    let command: string = '';
    let close_commands: string[] = [];
    this.printing_text = "";
    for (let print_char of Array.from(words)) {

      if (command == '' && print_char == "{") {
        command += " ";
      }
      else if (command != '' && print_char == '}') {
        // command = command + print_char;
        if (command.startsWith(" SPEED=")) {
          let speed = this.getTextAfterEquals(command);
          switch (speed) {
            case "default":
              this.speed = this.default_speed;
              break;
            default:
              this.speed = Number(speed);
              break;
          }
        }
        else if (command.startsWith(" I")) {
          this.printing_text += "<i>";
          close_commands.push("</i>")
        }
        else if (command.startsWith(" B")) {
          this.printing_text += "<b>";
          close_commands.push("</b>")
        }
        else if (command.startsWith(" #")) {
          this.printing_text += "<text style=\"color: " + command + "\">";
          close_commands.push("</text>")
        }
        else if (command == " END" && close_commands.length > 0) {
          let end = close_commands.pop();
          this.printing_text += end
        }
        command = '';
      }
      else if (command != '') {
        command += print_char
      }
      else {
        this.printing_text += print_char;
        this.printing_text += this.cursor;
        const speed = this.randomIntFromInterval(this.speed);
        await this.timer(speed); // then the created Promise can be awaited
        this.printing_text = this.printing_text.slice(0, (Array.from(this.cursor).length) * -1);
      }
    }

    while (true) {
      await this.timer(400);
      this.printing_text += this.cursor;
      await this.timer(400);
      this.printing_text = this.printing_text.slice(0, (Array.from(this.cursor).length) * -1); // (Array.from(this.cursor).length) * -1
    }
  }

  randomIntFromInterval(speed: number) { // min and max included 
    if (speed <= 0) {
      return 0
    }
    else {
      let max = speed + 120;
      let min = speed - 120;
      min = min < 0 ? 0 : min;
      max = max < 0 ? 0 : max;
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  }

  getTextAfterEquals(inputString: string) {
    const indexOfEquals = inputString.indexOf('=');
    if (indexOfEquals !== -1) {
      return inputString.substring(indexOfEquals + 1).trim();
    }
    return ''; // If '=' is not found, return an empty string
  }
}


