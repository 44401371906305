import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.scss']
})
export class MatrixComponent implements OnInit {
  maxtrixs: number[][] = [];
  timer = (ms: number | undefined) => new Promise(res => setTimeout(res, ms))

  screenHeight: number = 0;
  screenWidth: number = 0;

  constructor() {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  async ngOnInit(): Promise<void> {
    while (true) {
      this.maxtrixs = [];
      for (let ran of new Array(3)) {
        this.maxtrixs.push(this.getRandomInt(2, Math.floor(this.screenWidth / 9.61)))
      }
      await this.timer(1000);
    }

  }

  getRandomInt(max: number, range: number) {
    let bin: number[] = []
    for (let ran of new Array(range)) {
      bin.push(Math.floor(Math.random() * max))
    }
    return bin
  }
}
