import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HobbyComponent } from './hobby/hobby.component'
import { HomeComponent } from './home/home.component'
import { SoonComponent } from './soon/soon.component';

const routes: Routes = [
  { path: 'components', loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule) },
  { path: 'hobby', component: HobbyComponent },
  { path: 'home', component: HomeComponent },
  { path: '', component: SoonComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
