import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CmdScreenComponent } from '../components/cmd-screen/cmd-screen.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  words: string = ''
  @ViewChild(CmdScreenComponent) cmdScreenComponent?: CmdScreenComponent;

  constructor(private http: HttpClient) { }
  ngOnInit(): void {
    this.readFile("command/introduce.txt").subscribe((data: string) => {
      this.cmdScreenComponent?.onStartCounting(data);
    });
  }

  readFile(filename: string) {
    return this.http.get(`/assets/${filename}`, { responseType: 'text' });
  }
}
