<div class="bg-cmd">
    <nav class="navbar navbar-light bg-cmd-title justify-content-between">
        <a class="navbar-brand"></a>
        <span class="text-white">Terminal</span>
        <form class="form-inline">
            <button class="btn my-2 my-sm-0 text-white" type="submit">_</button>
          <button class="btn my-2 my-sm-0 text-white" type="submit">X</button>
        </form>
      </nav>
      <div class="text-white">
        <div class="row">
            <div class="col" style="white-space: pre-wrap" [innerHTML]="sanitizer.bypassSecurityTrustHtml(printing_text)">
            </div>
        </div>
      </div>
</div>