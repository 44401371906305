import { ComponentsRoutingModule } from './components-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ComponentsRoutingModule,
        SharedModule
    ]
})
export class ComponentsModule { }
