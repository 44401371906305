import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  full_title = 'I-TheE TheESaOz\' Website'
  title = '';
  cursor: string = '█';

  timer = (ms: number | undefined) => new Promise(res => setTimeout(res, ms))

  ngOnInit(): void {
    this.title = this.full_title;
    // this.onPlayTitle()
  }

  async onPlayTitle() {
    for (let char of Array.from(this.full_title)) {
      this.title += char;
      this.title += this.cursor;
      await this.timer(100); 
      this.title = this.title.slice(0, (Array.from(this.cursor).length) * -1);

    }

  }

  
}
